@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/values";

.component {
    :global(.ArrowButton) {
        width: values.$px_32;
        height: values.$px_32;
        background-color: values.$colorNeutral800;
        top: calc(50% - 70px) !important;
        :global(.qvIcon) {
            color: values.$colorWhite;
        }
    }
    :global(.ScrollIndicator) {
        display: none;
    }
}

.blogPost {
    display: flex;
    flex-direction: column;

    &__image {
        object-fit: cover;
        border-radius: values.$radius6;
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9; // 363 x 204
        margin-bottom: values.$space5;
    }
    &__title {
        font: values.$fontLabelL;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: values.$space3;
    }
    &__cta {
        font-size: values.$px_14;
        font-weight: 700;
        display: flex;
        align-items: center;
        column-gap: values.$space2;
        color: values.$colorRed500;
    }
    &__ctaText {
        text-decoration: underline;
        font-weight: 700;
    }
    &__ctaIcon {
        color: values.$colorRed500;
    }
}


