@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/shadows';
@use '$styles/variables/fonts';

.component {
  box-shadow: shadows.$elevationLevel1BoxShadow;
  padding: spacings.$M spacings.$S;
  margin: spacings.$M 0;
  background-color: colors.$white;

  @include mediaqueries.mediumUp {
    display: flex;
  }
}

.GarageCard {
  border: solid 2px transparent;

  cursor: pointer;
  position: relative;

  @include mediaqueries.mediumUp {
    // Compensate for the extra border
    margin-left: 1px;
    margin-right: 1px;
  }

  &:hover {
    background-color: colors.$neutral100;
  }
}

.fixter-certified-desktop-container {
  display: none;

  @include mediaqueries.mediumUp {
    display: block;
    position: absolute;
    top: -6px;
    right: -2px;
  }

  img {
    object-fit: contain;
  }
}

.fixter-certified-mobile-container {
  @include mediaqueries.mediumUp {
    display: none;
  }
}

.SelectedGarage {
  background-color: colors.$neutral100;

  .GarageInfoCardHeader {
    color: colors.$purple500;
  }
}

.GarageCtaButton {
  margin-bottom: spacings.$M;
  width: 95%;
}

.GarageCardLeftSection,
.GarageCardRightSection {
  width: 100%;

  @include mediaqueries.mediumUp {
    padding: 20px;
  }
}
