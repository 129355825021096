@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';

.latestArticlesTitleContainer {
  h2 {
    @include typography.headlinesH2TypographyStyles;
    color: colors.$neutral800;
    margin-top: 72px;
    margin-bottom: 16px;
    @include mediaqueries.mediumUp {
      margin-top: 40px;
    } 
  }
  p {
    @include typography.paragraphMTypography;
    color: colors.$neutral400;
    margin: 0;
  }
}