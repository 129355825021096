@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';

.blogPreviewCard {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: radii.$S;
  cursor: pointer;
  &:hover,
  &:active {
    h5 {
      text-decoration: underline;
    }
  }

  .previewImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: radii.$S;
    margin-bottom: 16px;
  }
  .previewContent {
    h5 {
      color: colors.$neutral800;
      margin-top: 12px !important;
      @include typography.headlinesH5TypographyStyles;
    }
    .shortDescription {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-height: 20px;
      @include mediaqueries.mediumUp {
        -webkit-line-clamp: 3;
      }
      p {
        color: colors.$neutral400;
        @include typography.paragraphLTypography;
      }
    }
    .publishedAt {
      color: colors.$neutral400;
      @include typography.labelSTypographyStyles;
    }
  }
}

.groups {
  display: flex;
  gap: spacings.$S;
  .groupContainer {
    padding: spacings.$XXS spacings.$XS;
    border-radius: radii.$L;
    border: 1px solid colors.$neutral200;
    .group {
      color: colors.$neutral600;
      @include typography.labelSTypographyStyles;
    }
  }
}
.groupContainer {
  border-radius: 56px;
  border: 1px solid colors.$neutral200;
  display: flex;
  .group {
    color: colors.$neutral600;
    @include typography.labelSTypographyStyles;
  }
}
